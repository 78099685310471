import React, { useState, useEffect } from "react";
import { Drawer, Input, Popover, notification } from "antd";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { urlSocketChat } from "../../../components/config/config";
import { GetListDataMessage, inputChat, editChat } from "../../../components/config/actions";
import DrawerInfoRoom from "./drawerInfoRoom";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { UploadFile } from "../../../components/Button/UploadFile";
import ChatList from "./chatList";
import './chatbubles.css';

const DrawerChatRoom = (props) => {
  const { setLastMessage, open, onClose, roomId, roomName, dateRoom, lastMessage } = props
  const json = JSON.parse(localStorage.getItem("userdata"));
  // const user_name = JSON.parse(localStorage.getItem("userdata")).username;
  // socket
  
  // state
  const [chatMessage, setChatMessage] = useState('');
  const [messageHistory, setMessageHistory] = useState([]);
  const [messageList, setMessageList] = useState([])
  const [curentOffset, setCurrentOffset] = useState(1)
  const [openInfo, setOpenInfo] = useState(false)
  const [totalMessage, setTotalMessage] = useState(0)
  const [dataParticipant, setDataParticipant] = useState([])
  const [fetching, setFetching] = useState(false);
  const [firstBottom, setFirstBottom] = useState(false)
  const [disableSent, setDisableSent] = useState(false)

  useEffect(() => {
    setCurrentOffset(1)
    setTotalMessage(0)
    setMessageList([])
    setChatMessage('')
    if(roomId){
      getChatParticipant();
      getRoomById();
      getMessage();
      readMessage();
    }
  }, [roomId]);

  useEffect(() => {
    if(curentOffset <=1){
      return;
    }
    getRestMessage();
  }, [curentOffset])

  useEffect(() => {
    if(lastMessage){
      let lastMessageData = JSON.parse(lastMessage.data);
      if(lastMessageData.room_id==roomId){
        setFirstBottom(false);
        let tmpArr = [lastMessageData.data];
        setMessageList([...tmpArr, ...messageList]);
        readMessage();
      }
      setLastMessage(null);
    }
  }, [lastMessage])

  // handle function
  const getMessage = () => {
    // setLoading(true);

    GetListDataMessage(
      `chat/message?order=desc&sort_by=created_date&offset=1&limit=25&chat_room_id=${roomId}`
    )
      .then((response) => {
        if (response.responseStatus == "SUCCESS") {
          // setLoading(false);
          if (response.data == null) {
            setMessageList([]);
          } else {
            // const sortDesc = response.data.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));
            // const sordAsc = response.data.sort(
            //   (a, b) => new Date(a.created_date) - new Date(b.created_date)
            // );
            setTotalMessage(response.count);
            setMessageList(response.data);
          }
        }
      })
      .catch((error) => {
        // setLoading(false);
        if (error.responseStatus == "ERROR") {
          notification.error({
            message: "Terjadi Kesalahan",
            description: `${error.data.data}`,
            placement:'topRight',
            style:{zIndex:2000}
          });
        }
      });
  };

  const getRestMessage = async () => {
    GetListDataMessage(
      `chat/message?order=desc&sort_by=created_date&offset=${curentOffset}&limit=25&chat_room_id=${roomId}`
    )
      .then((response) => {
        if (response.responseStatus == "SUCCESS") {
          // setLoading(false);
          if (response.data == null) {
            
          } else {
            // const sortDesc = response.data.sort((a, b) => parseFloat(b.id) - parseFloat(a.id));
            // const sordAsc = response.data.sort(
            //   (a, b) => new Date(a.created_date) - new Date(b.created_date)
            // );
            setMessageList([...messageList, ...response.data]);
            setFetching(false);
          }
        }
      })
      .catch((error) => {
        // setLoading(false);
        if (error.responseStatus == "ERROR") {
          notification.error({
            message: "Terjadi Kesalahan",
            description: `${error.data.data}`,
            placement:'topRight',
            style:{zIndex:2000}
          });
        }
      });
  }

  const readMessage = () => {
    if(!roomId){
      return;
    }
    const data = {
      chat_room_id:roomId
    }

    editChat(`chat/all/read`, data)
      .then((response) => {
        if (response.responseStatus === "SUCCESS") {
          // 
        }
      })
      .catch((error) => {
        if (error.data.responseStatus == "ERROR") {
          // 
        }
      });
  }

  const compare  = (a, b) => {
    if(a.user && a.user.first_name && b.user && b.user.first_name){
      if ( a.user.first_name < b.user.first_name ){
        return -1;
      }
      if ( a.user.first_name > b.user.first_name ){
        return 1;
      }
    }
    return 0;
  }

  const getChatParticipant = () => {
    GetListDataMessage(`chat/participant?chat_room_id=${roomId}`)
      .then((response) => {
        if (response.responseStatus == "SUCCESS") {
          let arrParticipant = response.data;
          arrParticipant.sort( compare );
          let indexSelf = arrParticipant.findIndex((val) => val.user_id === parseInt(json.user_id));
          let dataSelft = arrParticipant[indexSelf];
          arrParticipant.splice(indexSelf, 1);
          arrParticipant = [dataSelft, ...arrParticipant];
          setDataParticipant(arrParticipant);
        }
      })
      .catch((error) => {
        if (error.responseStatus == "ERROR") {
          notification.error({
            message: "Terjadi Kesalahan",
            description: `${error.data.data}`,
            placement:'topRight',
            style:{zIndex:2000}
          });
        }
      });
  };

  const getRoomById = () => {
    GetListDataMessage(`chat/room/${roomId}`)
      .then((response) => {
        if (response.responseStatus == "SUCCESS") {
          
        }
      })
      .catch((error) => {
        if (error.responseStatus == "ERROR") {
          notification.error({
            message: "Terjadi Kesalahan",
            description: `${error.data.data}`,
            placement:'topRight',
            style:{zIndex:2000}
          });
        }
      });
  };

  const createMessage = (data) => {
    inputChat(`chat/message`, data)
      .then((response) => {
        if (response.responseStatus === "SUCCESS") {
          setDisableSent(false);
          getMessage();
          readMessage();
        }
      })
      .catch((error) => {
        if (error.data.responseStatus == "ERROR") {
          notification.error({
            message: "Terjadi Kesalahan",
            description: `${error.data.data}`,
            placement:'topRight',
            style:{zIndex:2000}
          });
        }
      });
  };

  const handleChat = () => {
    if(disableSent){
      return;
    }
    if(!chatMessage && chatMessage.length < 1){
      return;
    }
    setDisableSent(true)
    setChatMessage('');
    setFirstBottom(false);
    const data = {
      category: 1,//1 regular 2 file
      data: chatMessage,
      chat_room_id: roomId,
      sender: {
        first_name: json.first_name,
        last_name: json.last_name ? json.last_name : '',
        user_id: json.user_id,
      },
    };

    createMessage(data);
  }

  const handleUpload = (file) => {

    if (file.fileSize >= 10008874) {
      notification.error({
        message: "Gagal!",
        description: 'Ukuran file tidak boleh lebih dari 10mb',
        placement:'topRight',
        style:{zIndex:2000}
      });
      // window.alert(this.state.messageSize);
      return;
    }

    // data: file.base64.split(",")[1],
    const data = {
      filename: file.filename,
      chat_room_id: roomId,
      sender: {
        first_name: json.first_name,
        last_name: json.last_name ? json.last_name : '',
        user_id: json.user_id,
      },
    };

    if (
      file.filename.split(".").pop() === "pdf" ||
      file.filename.split(".").pop() === "xlsx" ||
      file.filename.split(".").pop() === "xls" ||
      file.filename.split(".").pop() === "docx" ||
      file.filename.split(".").pop() === "doc" ||
      file.filename.split(".").pop() === "ppt" ||
      file.filename.split(".").pop() === "pptx"
    ){
      data['data'] =  file.base64.split(",")[1];
      data['category'] = 3;
    }else{
      data['data'] = file.base64
      data['category'] = 2;
    }
    createMessage(data);
  }

  const customButton = () => (
    <i className="fa fa-file pointer-a"></i>
  )

  const RightInput = () => {
    return (
      <div style={{ display:'flex', flexDirection: 'row', justifyContent:'center', alignItems:'center' }}>
        <i onClick={handleChat} className="fa fa-paper-plane pointer-a" style={{ marginRight: '10px'}}></i>
        <UploadFile typeFile=".doc, .docx, .xls, .xlsx, .pdf, .ppt, .pptx, image/*" customButton={customButton} upload={(file) => handleUpload(file)} />
      </div>
    );
  }

  const addEmoji = (emoji) => {
    let textMessage = chatMessage+emoji.native;
    setChatMessage(textMessage);
  };

  const LeftInput = () => (
    <Popover className="popover-chat" style={{zIndex:1505}} content={<Picker emoji="" title="" native={true} onSelect={addEmoji} />} title="Title" trigger="click">
      <i style={{ cursor:'pointer' }} className="fa fa-smile-o "></i>
    </Popover>
  )

  const TitleHeaderRoomChat  = () => {
    return(
      <div onClick={handleOpenInfo} style={{ width:'100%', display:'flex', flexDirection:'column', cursor:'pointer' }}>
        <h6 style={{ marginBottom:0, paddingBottom:0}}>{roomName ? roomName : ''}</h6>
        <small style={{fontSize:'.65em'}}>Klik untuk info grup</small>
      </div>
    )
  }

  // <UploadFile typeFile=".doc, .docx, .xls, .xlsx, .pdf, .ppt, image/*" customButton={this.customButton} upload={(file) => this.handleUpload(file, row)} />

  const handleMoreMessage = () => {
    if(fetching){
      return;
    }
    setFetching(true);
    setCurrentOffset(prev => prev+1);
  }

  const handleOpenInfo = () => {
    setOpenInfo(true)
  }

  const handleCloseInfo = () => {
    setOpenInfo(false)
  }

  const handleKeyDown = (e) => {
    if(disableSent){
      return;
    }
    if(e.key==='Enter' || e.key==='enter'){
      handleChat();
    }
  }

  return(
    <div>
      <DrawerInfoRoom  data={dataParticipant} open={openInfo} onClose={handleCloseInfo} roomName={roomName} dateRoom={dateRoom} />
      <Drawer className="chat-room" style={{ zIndex: 1501 }} width={500} visible={open} onClose={onClose} title={<TitleHeaderRoomChat />}>
        {/* <div style={{ height:'100%', display:'flex', flexDirection:'column-reverse', paddingBottom:'2em' }}>
          <div style={{backgroundColor:'blue', height:'50px',margin:'10px 0'}}>asdasd</div>
        </div> */}
        {/* <div style={{ position:'absolute', top:'4em', height:'50px', backgroundColor:'lightgrey',right:'1.15em', left:0, opacity:.5 }}>
          <h1>WTF</h1>
        </div> */}
        <ChatList open={open} firstBottom={firstBottom} setFirstBottom={setFirstBottom} messageList={messageList} moreMessage={handleMoreMessage} totalMessage={totalMessage} roomId={roomId} />
        <div style={{ position:'absolute', bottom:'1.2em', right: ' 1.2em', left:'1.2em', display:'flex', justifyContent:'center', alignItems:'center' }}>
          <Input value={chatMessage} onKeyPress={handleKeyDown} onChange={(e) => setChatMessage(e.target.value)} addonBefore={<LeftInput />} addonAfter={<RightInput />} />
        </div>
      </Drawer>
    </div>
  )
}

export default DrawerChatRoom;