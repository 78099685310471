import React, { Component } from "react";
import { Tabs } from "antd";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
// import RegistrasiAsesor from "./RegisterAsesor";
// import RegistrasiAsesi from "./RegisterAsesi";
import RegisterAsesi from "./newRegisterAsesi";
import RegistrasiAsesor from "./newRegisterAsesor";
const { TabPane } = Tabs;
class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
      tabs: "",
    };
  }

  render() {
    return (
      <div className="backgroundColor">
        <Row className="justify-content-center">
          <Col style={{ marginTop: "50px", marginBottom: "50px" }}>
            <Card
              style={{
                width: "92%",
                margin: "auto",
                borderRadius: 10,
              }}
            >
              <CardBody>
                <Tabs defaultActiveKey="1">
                  <TabPane
                    className="tabPane"
                    tab={<p className="mySpan">Asesi</p>}
                    key="1"
                  >
                    <RegisterAsesi />
                  </TabPane>

                  <TabPane
                    className="tabPane"
                    tab={<p className="mySpan">Asesor</p>}
                    key="2"
                  >
                    <RegistrasiAsesor />
                  </TabPane>
                </Tabs>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Register;
