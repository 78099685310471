import React, { useState, useRef } from "react";
import moment from "moment";
import PDFJs from "../../../backends/pdfjs";
import PDFViewer from "../../../components/PDFViewer/PDFViewer";
import { Modal } from "antd";
// import InfiniteScroll from "react-infinite-scroll-component";
import InfiniteScroll from 'react-infinite-scroller';
import { useEffect } from "react";
import './chatbubles.css';

const ChatList = (props) => {
  const { messageList, moreMessage, totalMessage, roomId, firstBottom, setFirstBottom, open } = props;
  const [userId, setUserId] = useState(JSON.parse(localStorage.getItem("userdata")).user_id);
  const [bodySetted, setBodySetted] = useState(false);
  const [openPreview, setOpenPreview] = useState(false)
  const [srcPreview, setSrcPreview] = useState('');
  // const [firstBottom, setFirstBottom] = useState(false)
  // const [arrayDate, setArrayDate] = useState([])
  // ref
  const drawerRef = useRef(null);
  const drawerBody = document.getElementById('body-chat');
  const bottomMessage = useRef(null);
  const topMessage = useRef(null);

  const LeftChat = ({ chat, sender, date, detail }) => (
    <div style={{ padding: '10px', width:'100%', display:'flex' }}>
      <div className="tri-left" style={{ 
          padding: '10px', backgroundColor:'white', color:'black', borderRadius:'10px', maxWidth:'90%', minWidth:'35%'
        }}>
        <h6 style={{ color:'red' }}>{sender}</h6>
        {detail ? (
          <div style={{ width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <RenderFile src={chat} />
          </div>
        ) : (
          <p>{chat}</p>
        )}
        <small>{moment(date).format('HH:mm')}</small>
      </div>
    </div>
  )
  
  const RightChat = ({ chat, date, detail }) => (
    <div style={{ padding: '10px', width:'100%', display:'flex', justifyContent:'flex-end' }}>  
      <div style={{ 
        padding: '10px', backgroundColor:'#7cf7ab', color:'black', borderRadius:'10px', maxWidth:'90%', minWidth:'35%'
      }}>
        <h6>Anda</h6>
        {detail ? (
          <div style={{ width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <RenderFile src={chat} />
          </div>
        ) : (
          <p>{chat}</p>
        )}
        <small>{moment(date).format('HH:mm')}</small>
      </div>
    </div>
  )
  
  const RenderFile = ({ src }) => {
    let ext = src.split(".").pop();
    let fileName = src.split("/").pop();
    if(ext === "pdf"){
      return(
        <div onClick={() => handleOpenPreview(src)} style={{ display:'flex', flexDirection:'column', justifyContent:'center', textAlign:'center', cursor:'pointer'}}>
          <i style={{ fontSize: '3em'}} className="fa fa-file-pdf-o"></i>
          <small>{fileName}</small>
        </div>
      )
    }else if(ext === "xls" || ext === "xlsx" || ext === "doc" || ext === "docx" || ext === "ppt" || ext === "pptx"){
      let icon = "";
      if(ext === "xls" || ext === "xlsx"){
        icon = "fa-file-excel-o";
      }else if(ext === "doc" || ext === "docx"){
        icon = "fa-file-word-o ";
      }else if(ext === "ppt" || ext === "pptx"){
        icon = "fa-file-powerpoint-o";
      }else{
        icon = "fa-file";
      }
      return(
        <div onClick={() => handleOpenDocs(src)} style={{ display:'flex', flexDirection:'column', justifyContent:'center', textAlign:'center', cursor:'pointer'}}>
          <i style={{ fontSize: '3em'}} className={`fa ${icon}`}></i>
          <small>{fileName}</small>
        </div>
      )
    }else{
      return(
        <img src={src} style={{width:'100%'}} />
      )
    }
  }
  
  useEffect(() => {
    setFirstBottom(false);
    return () => {
      setFirstBottom(false);
    };
  }, [roomId]);


  useEffect(() => {
    if(drawerRef.current){
      drawerRef.current.parentElement.setAttribute('id','body-chat');
      setBodySetted(true);
    }
  }, [drawerRef]);

  const RenderDateMiddle = ({ date }) => {
    return (
      <div style={{ width:'100%', display:'flex', justifyContent:'center', alignItems:'center' }}>
        {date}
      </div>
    )
  }
  
  const RenderMessage = (props) => {
    const { value, date } = props
    let viewDate = '';
    let fullName = `${value.sender.first_name ? value.sender.first_name : ''} ${value.sender && value.sender.last_name ? value.sender.last_name : ''}`;
    if(date){
      viewDate = date.format("dddd, DD MMMM YYYY");
      if(moment(date).isSame(moment(), 'day')){
        viewDate = 'Hari Ini';
      }else if(moment(date).isSame(moment().subtract(1, 'days'))){
        viewDate = 'Kemarin';
      }
    }
    if(value.sender_id==parseInt(userId)){
      return (
        <React.Fragment>
          <RightChat chat={value.data} date={value.created_date} detail={value.detail} />
          {date ? <RenderDateMiddle date={viewDate} /> : null}
        </React.Fragment>
      )
    }else{
      return (
        <React.Fragment>
          <LeftChat chat={value.data} sender={fullName} date={value.created_date} detail={value.detail} />
          {date ? <RenderDateMiddle date={viewDate} /> : null}
        </React.Fragment>
      )
    }
  }

  const handleGetNextMessage = () => {

    // return;
    if(!firstBottom){
      return;
    }

    if(totalMessage==messageList.length){
      return;
    }
    moreMessage();
  }

  const scrollBottom = async () => {
    if(!firstBottom){
      await delay(1200);
      bottomMessage.current.scrollIntoView({ behavior: "auto" });
      setFirstBottom(true);
    }
  }

  const setRenderMessage = () => {
    let arrayDate = [];
    return (
      <React.Fragment>
        {messageList.map((val, index) => {
          if(val){
            let createdDate = val.created_date ? moment(val.created_date) : moment();
            let find = arrayDate.findIndex((valDate) => moment(valDate).isSame(createdDate, 'day'));
            let nextSame = false;
            if(messageList.length!==index+1){
              nextSame = moment(val.created_date).isSame(messageList[index+1].created_date, 'day');
            }
            let date = '';

            if(!nextSame && find < 0){
              arrayDate.push(createdDate);
              date = createdDate;
            }


            if(index+1===messageList.length){
              scrollBottom();
            }
            
            return <RenderMessage value={val} key={index} date={date} />
          }
        })}
      </React.Fragment>
    )
  }

  const handleOpenPreview = (src) => {
    setSrcPreview(src);
    setOpenPreview(true);
  }

  const handleOpenDocs = (src) => {
    let link = "https://docs.google.com/gview?embedded=true&url="+src;
    window.open(link, '_blank').focus();
  }

  const delay = ms => new Promise(res => setTimeout(res, ms));

  return(
    <div ref={drawerRef} style={{ height:'100%', display:'flex', paddingBottom:'2em' }}>
      {/* Modal Document */}
      <Modal
        title={`Preview PDF`}
        width="635px"
        visible={openPreview}
        onCancel={() => setOpenPreview(false)}
        cancelText="Tutup"
        okButtonProps={{ style:{display:'none'} }}
        className="modal-chat"
      >
          <PDFViewer backend={PDFJs} src={srcPreview} />
      </Modal>
      {bodySetted && (
        <InfiniteScroll
          loadMore={handleGetNextMessage}
          hasMore={firstBottom ? totalMessage!==messageList.length : false}
          loader={<h4 key={0}></h4>}
          useWindow={false}
          getScrollParent={() => drawerBody}
          isReverse={true}
          initialLoad={false}
          style={{ display: 'flex', flexDirection: 'column-reverse',width:'100%',minHeight:'80vh',overflow:'hidden' }}
        >
          <div style={{ height:'10px'}} onClick={handleGetNextMessage} ref={bottomMessage} />
          {setRenderMessage()}
        </InfiniteScroll>
      )}
    </div>
  )
}

export default ChatList;