import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
} from "reactstrap";
import PropTypes from "prop-types";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler,
} from "@coreui/react";
import {
  baseUrl,
  path_notif,
  Brand_LSP,
  getLsp,
  getRole,
  path_users,
  getUserId,
} from "../../components/config/config";
import "react-notifications/lib/notifications.css";
import ButtonEdit from "../../components/Button/ButtonEdit";
import "../../css/Dashboard.css";
import firebase from "firebase/app";
import "firebase/messaging";
import axios from "axios";
import { Digest } from "../Helpers/digest";
import { multiLanguage } from "../../components/Language/getBahasa";
import "react-notifications/lib/notifications.css";
import { black } from "material-ui/styles/colors";
import { connect } from "react-redux";
import { insertData } from "../../components/config/actions";
import MediaQuery from "react-responsive";
// import "../../css/All.css";

// import "../../css/LSP_Abi.css";

// import "../../css/abi.css";

// if (!firebase.app.length) {
//   firebase.initializeApp({});
// }

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logged_in: false,
      roleMag: false,
      roleApl: false,
      roleAcs: false,
      roleAdt: false,
      roleDev: false,
      notifications: false,
      badge: false,
      sumNotification: 0,
      hide: false,
      modal: false,
      backdrop: true,
      notif: "Notification",
      titleNotif: "Notification",
      detailAssessor: "",
      detailStatus: "",
      detailSchedule_id: "",
      bellNotif: false,
      payloadNotif: [],
      detailNotif: "",
      bahasa: "en",
      user_id: JSON.parse(localStorage.getItem("userdata")),
      notif: 4,
    };
  }

  componentDidMount() {
    const auth = Digest(path_notif, "GET");
    const options = {
      method: auth.method,
      headers: {
        Authorization: auth.digest,
        "X-Lsp-Date": auth.date,
        "Content-Type": "application/json",
      },
      url: baseUrl + path_notif + "?sort=is_read",
    };
    // axios(options).then((res) => {
    //   this.setState({ payloadNotif: res.data.data });
    // });
    // notification in website page
    // const messaging = firebase.messaging();
    // messaging.onMessage((payload) => {
    //   const message = payload.notification.body;
    //   const title = payload.notification.title;
    //   const timeOut = 2000;
    //   this.setState({
    //     notif: "New Message",
    //     titleNotif: title,
    //     bellNotif: !this.state.bellNotif,
    //     badge: true,
    //     sumNotification: this.state.sumNotification + 1,
    //   });

    //   switch (payload.notification.click_action) {
    //     case "LSPACSNTFOFR":
    //       NotificationManager.success(message, title, timeOut);
    //       break;

    //     case "LSPACSNTFDEF":
    //       NotificationManager.info(message, title, timeOut);
    //       break;

    //     default:
    //       break;
    //   }
    // });
  }

  toggle = (event) => {
    const notification_id = event.target.value;
    const auth = Digest(path_notif + "/" + notification_id, "GET");
    const options = {
      method: auth.method,
      headers: {
        Authorization: auth.digest,
        "X-Lsp-Date": auth.date,
        "Content-Type": "application/json",
      },
      url: baseUrl + path_notif + "/" + notification_id,
    };
    axios(options).then((res) => {
      const json = JSON.parse(res.data.data.data);
      this.setState({
        detailAssessor: json.accessor_id,
        detailSchedule_id: json.assessment_id,
        detailStatus: json.last_state_assessor,
      });
    });
    this.setState({
      modal: !this.state.modal,
      backdrop: "static",
    });
  };

  toggleExit = () => {
    const auth = Digest(path_notif, "GET");
    const options = {
      method: auth.method,
      headers: {
        Authorization: auth.digest,
        "X-Lsp-Date": auth.date,
        "Content-Type": "application/json",
      },
      url: baseUrl + path_notif + "?sort=is_read",
    };
    axios(options).then((res) => {
      this.setState({ payloadNotif: res.data.data });
    });
    this.setState({
      modal: !this.state.modal,
      bellNotif: false,
      badge: false,
      sumNotification: 0,
      notif: "Notification",
    });
  };

  // logout = () => {
  //   localStorage.removeItem("role");
  //   localStorage.clear();
  //   localStorage.removeItem("role");
  //   localStorage.removeItem("lsp");
  //   this.setState({ logged_in: true });
  //   window.location.reload();
  // };

  logout = async () => {
    this.setState({
      loading: true,
    });
    
    const tokenData = localStorage.getItem("secret_key");
    
    await insertData("/users/logout", {
      token: tokenData,
    })
    .then((result) => {
      this.setState({
        loading: false,
      });
      localStorage.clear();
      window.location.reload();
    })
    .catch((e) => {
      this.setState({
        loading: false,
      });
      localStorage.clear();
      window.location.reload();
    });

    localStorage.clear();
    window.location.reload();
  };

  setting = () => {
    if (getRole() === "ACS") {
      this.setState({ roleAcs: true });
    } else if (getRole() == "APL") {
      this.setState({ roleApl: true });
    } else if (getRole() == "ADT") {
      this.setState({ roleAdt: true });
    } else {
      this.setState({ roleDev: true });
    }
  };
  render() {
    const { title, Logo } = Brand_LSP("demo");
    const { LogoAbi } = Brand_LSP("lsp_abi");
    const { LogoEnergi } = Brand_LSP("lsp_energi");
    // const value = getUserId();
    if (this.state.logged_in) {
      return <Redirect to={"/login"} />;
    }
    if (this.state.roleAcs) {
      window.location.href = `/users/edit-users-accessor/${this.state.user_id.user_id}`;
      // return <Redirect to={"/profile/asesor"} />;
    }
    if (this.state.roleApl) {
      window.location.href = `/users/edit-users-aplicant/${this.state.user_id.user_id}`;
    }

    if (this.state.roleAdt) {
      window.location.href = "/profile/admintuk";
    }
    if (this.state.roleDev) {
      window.location.href = "/profile/user";
    }

    var user = JSON.parse(localStorage.getItem("userdata"));
    const externalCloseBtn = (
      <button
        className="close"
        style={{ position: "absolute", top: "15px", right: "15px" }}
        onClick={this.toggle}
      >
        &times;
      </button>
    );

    const DemoTheme = React.lazy(() => import("../Demo"));
    const AbiTheme = React.lazy(() => import("../Abi"));

    return (
      <React.Fragment>
        {/* {getLsp() == "1" ? (
          <React.Fragment>
            <AppNavbarBrand
              full={{ src: Logo, width: 60, height: 40, alt: "LSP Logo" }}
            />
            <Nav className="ml-auto" navbar>
              <div style={{ marginRight: "20px" }}>
                <Button className="btn-notif" title={`Chat`}>
                  <i
                    className="fa fa-bell"
                    style={{ color: "blue !important" }}
                  />
                  <span class="badge badge-danger">9</span>
                </Button>
              </div>

              <AppHeaderDropdown direction="down">
                {`Hai, ${user.first_name} ${user.last_name}`}
                <DropdownToggle
                  nav
                  className="badgeTooltip"
                  onClick={this.hideBadge}
                >
                  <img
                    src={baseUrl + user.picture + "?width=56&height=56"}
                    className="img-avatar"
                    alt="admin"
                  />
                </DropdownToggle>
                <DropdownMenu right style={{ right: "auto" }}>
                  <DropdownItem header tag="div" className="text-center">
                    {user.role_name}
                  </DropdownItem>
                  <DropdownItem onClick={this.setting}>
                    {" "}
                    Pengaturan
                  </DropdownItem>
                  <DropdownItem
                    className="fa fa-sign-out"
                    onClick={this.logout}
                  >
                    {" "}
                    {multiLanguage.logout}
                  </DropdownItem>
                </DropdownMenu>
              </AppHeaderDropdown>
            </Nav>
          </React.Fragment>
        ) : getLsp() == "2" ? (
          <React.Fragment>
            {" "}
            <AppNavbarBrand
              full={{ src: LogoAbi, width: 60, height: 40, alt: "lsp_abi" }}
            />
            <Nav className="ml-auto" navbar>
              <div style={{ marginRight: "20px" }}>
                <Button className="btn-notif" title={`Chat`}>
                  <i
                    className="fa fa-bell"
                    style={{ color: "blue !important" }}
                  />
                  <span class="badge badge-danger">9</span>
                </Button>
              </div>
              <AppHeaderDropdown direction="down">
                {`Hai, ${user.first_name} ${user.last_name}`}
                <DropdownToggle
                  nav
                  className="badgeTooltip"
                  onClick={this.hideBadge}
                >
                  <img
                    src={baseUrl + user.picture + "?width=56&height=56"}
                    className="img-avatar"
                    alt="admin"
                  />
                </DropdownToggle>
                <DropdownMenu right style={{ right: "auto" }}>
                  <DropdownItem header tag="div" className="text-center">
                    {user.role_name}
                  </DropdownItem>
                  <DropdownItem onClick={this.setting}>
                    {" "}
                    Pengaturan
                  </DropdownItem>
                  <DropdownItem
                    className="fa fa-sign-out"
                    onClick={this.logout}
                  >
                    {" "}
                    {multiLanguage.logout}
                  </DropdownItem>
                </DropdownMenu>
              </AppHeaderDropdown>
            </Nav>
          </React.Fragment>
        ) : getLsp() == "3" ? (
          <React.Fragment>
            <AppNavbarBrand
              full={{ src: LogoEnergi, width: 60, height: 40, alt: "LSP Logo" }}
            />
            <Nav className="ml-auto" navbar>
              <div style={{ marginRight: "20px" }}>
                <Button className="btn-notif" title={`Chat`}>
                  <i
                    className="fa fa-bell"
                    style={{ color: "blue !important" }}
                  />
                  <span class="badge badge-danger">9</span>
                </Button>
              </div>
              <AppHeaderDropdown direction="down">
                {`Hai, ${user.first_name} ${user.last_name}`}
                <DropdownToggle
                  nav
                  className="badgeTooltip"
                  onClick={this.hideBadge}
                >
                  <img
                    src={baseUrl + user.picture + "?width=56&height=56"}
                    className="img-avatar"
                    alt="admin"
                  />
                </DropdownToggle>
                <DropdownMenu right style={{ right: "auto" }}>
                  <DropdownItem header tag="div" className="text-center">
                    {user.role_name}
                  </DropdownItem>
                  <DropdownItem onClick={this.setting}>
                    {" "}
                    Pengaturan
                  </DropdownItem>
                  <DropdownItem
                    className="fa fa-sign-out"
                    onClick={this.logout}
                  >
                    {" "}
                    {multiLanguage.logout}
                  </DropdownItem>
                </DropdownMenu>
              </AppHeaderDropdown>
            </Nav>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <AppNavbarBrand
              full={{ src: Logo, width: 60, height: 40, alt: "LSP Logo" }}
            />
            <Nav className="ml-auto" navbar>
              <div style={{ marginRight: "20px" }}>
                <Button className="btn-notif" title={`Chat`}>
                  <i
                    className="fa fa-bell"
                    style={{ color: "blue !important" }}
                  />
                  <span class="badge badge-danger">9</span>
                </Button>
              </div>
              <AppHeaderDropdown direction="down">
                {`Hai, ${user.first_name} ${user.last_name}`}
                <DropdownToggle
                  nav
                  className="badgeTooltip"
                  onClick={this.hideBadge}
                >
                  <img
                    src={baseUrl + user.picture + "?width=56&height=56"}
                    className="img-avatar"
                    alt="admin"
                  />
                </DropdownToggle>
                <DropdownMenu right style={{ right: "auto" }}>
                  <DropdownItem header tag="div" className="text-center">
                    {user.role_name}
                  </DropdownItem>
                  <DropdownItem onClick={this.setting}>
                    {" "}
                    Pengaturan
                  </DropdownItem>
                  <DropdownItem
                    className="fa fa-sign-out"
                    onClick={this.logout}
                  >
                    {" "}
                    {multiLanguage.logout}
                  </DropdownItem>
                </DropdownMenu>
              </AppHeaderDropdown>
            </Nav>
          </React.Fragment>
        )} */}

        {/* {getLsp() == "1" ? (
          <DemoTheme>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className={this.props.className}
              backdrop={this.state.backdrop}
              external={externalCloseBtn}
            >
              <ModalHeader>Detail Notification</ModalHeader>
              <ModalBody>
                <Row>
                  <Col xs="4">Accessor</Col>
                  <Col xs="6">: {this.state.detailAssessor}</Col>
                </Row>
                <Row>
                  <Col xs="4"> Status</Col>
                  <Col xs="6">: {this.state.detailStatus}</Col>
                </Row>
                <Row>
                  <Col xs="4"> Assessment</Col>
                  <Col xs="6">: {this.state.detailSchedule_id}</Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggleExit}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
            <AppSidebarToggler className="d-lg-none" display="md" mobile />
            <NotificationContainer />
          </DemoTheme>
        ) : getLsp() == "2" ? (
          <AbiTheme>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className={this.props.className}
              backdrop={this.state.backdrop}
              external={externalCloseBtn}
            >
              <ModalHeader>Detail Notification</ModalHeader>
              <ModalBody>
                <Row>
                  <Col xs="4">Accessor</Col>
                  <Col xs="6">: {this.state.detailAssessor}</Col>
                </Row>
                <Row>
                  <Col xs="4"> Status</Col>
                  <Col xs="6">: {this.state.detailStatus}</Col>
                </Row>
                <Row>
                  <Col xs="4"> Assessment</Col>
                  <Col xs="6">: {this.state.detailSchedule_id}</Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggleExit}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
            <AppSidebarToggler className="d-lg-none" display="md" mobile />

            <NotificationContainer />
          </AbiTheme>
        ) : (
          <DemoTheme>
            <Modal
              isOpen={this.state.modal}
              toggle={this.toggle}
              className={this.props.className}
              backdrop={this.state.backdrop}
              external={externalCloseBtn}
            >
              <ModalHeader>Detail Notification</ModalHeader>
              <ModalBody>
                <Row>
                  <Col xs="4">Accessor</Col>
                  <Col xs="6">: {this.state.detailAssessor}</Col>
                </Row>
                <Row>
                  <Col xs="4"> Status</Col>
                  <Col xs="6">: {this.state.detailStatus}</Col>
                </Row>
                <Row>
                  <Col xs="4"> Assessment</Col>
                  <Col xs="6">: {this.state.detailSchedule_id}</Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button color="secondary" onClick={this.toggleExit}>
                  Cancel
                </Button>
              </ModalFooter>
            </Modal>
            <AppSidebarToggler className="d-lg-none" display="md" mobile />

            <NotificationContainer />
          </DemoTheme>
        )} */}

        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          backdrop={this.state.backdrop}
          external={externalCloseBtn}
        >
          <ModalHeader>Detail Notification</ModalHeader>
          <ModalBody>
            <Row>
              <Col xs="4">Accessor</Col>
              <Col xs="6">: {this.state.detailAssessor}</Col>
            </Row>
            <Row>
              <Col xs="4"> Status</Col>
              <Col xs="6">: {this.state.detailStatus}</Col>
            </Row>
            <Row>
              <Col xs="4"> Assessment</Col>
              <Col xs="6">: {this.state.detailSchedule_id}</Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.toggleExit}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        {/* <AppSidebarToggler className="d-md-down-none" display="lg" /> */}
        <MediaQuery minWidth={768}>
          <AppNavbarBrand
            full={{ src: Logo, width: 150, height: 60, alt: "LSP Logo" }}
          />
        </MediaQuery>
        {/* <AppNavbarBrand
          full={{ src: Logo, width: 150, height: 60, alt: "LSP Logo" }}
        /> */}

        <NotificationContainer />

        <Nav className="ml-auto" navbar>
          <AppHeaderDropdown direction="down">
            {`Hai, ${user.first_name} ${user.last_name}`}
            <DropdownToggle
              nav
              className="badgeTooltip"
              onClick={this.hideBadge}
            >
              <img
                src={baseUrl + user.picture + "?width=56&height=56"}
                className="img-avatar"
                alt="admin"
              />
            </DropdownToggle>

            <DropdownMenu right style={{ right: "auto" }}>
              <DropdownItem header tag="div" className="text-center">
                {user.role_name}
              </DropdownItem>
              <DropdownItem onClick={this.setting}> Pengaturan</DropdownItem>
              <DropdownItem className="fa fa-lock" onClick={this.logout}>
                {" "}
                {multiLanguage.logout}
              </DropdownItem>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}
DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  return {
    notif: state.isRead,
  };
};
export default connect(mapStateToProps)(DefaultHeader);
