import React, { Component, Suspense } from "react";
import { PostData } from "../services/PostData";
import { insertData } from "../components/config/actions";
import { Redirect, Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Alert,
  Form,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from "reactstrap";
import { Input } from "antd";
import { withAlert } from "react-alert";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "firebase/messaging";
// import "../css/All.css";
import "../css/LSP_Demo.css";
import "../scss/style.css";
// import "../css/Button.css";
import {
  baseUrl,
  path_forgotPass,
  path_refreshToken,
  Brand_LSP,
  // getLsp,
} from "../components/config/config";
// import "antd/dist/antd.css";
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingLogo: true,
      username_email: "",
      password: "",
      logged_in: false,
      message: "",
      hidden: true,
      modal: false,
      nestedModal: false,
      closeAll: false,
      response: "",
      register_id: "",
      lsp_id: "",
    };
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  componentDidMount() {
    // const data1 = import("../css/LSP_Demo.css");
    // const data2 = import("../css/LSP_Abi.css");
    // this.setState({
    //   lsp_id: 2,
    // });
    // if (this.state.lsp_id === 1) {
    //   data1;
    // } else if (this.state.lsp_id == 2) {
    //   data2;
    // }

    window.location.hostname;
    // console.log("location", window.location.hostname);
  }

  toggleNested = (event) => {
    event.preventDefault();
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: false,
    });

    var data = new FormData();
    data.append("email", this.state.email);
    const options = {
      method: "POST",
      url: baseUrl + path_forgotPass,
      data: data,
    };
    axios(options).then((response) => {
      return response;
    });
  };

  toggleAll = () => {
    this.setState({
      nestedModal: !this.state.nestedModal,
      closeAll: true,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  setLocalStorage = (responseJSON) => {
    this.setState({ logged_in: true });
    localStorage.setItem("userdata", JSON.stringify(responseJSON.data));
    localStorage.setItem("secret_key", responseJSON.secret_key);
    localStorage.setItem("logged_in", responseJSON.data.logged_in);
    localStorage.setItem("identity_type", responseJSON.identity_type);
    localStorage.setItem("role", responseJSON.data.role_code);
    localStorage.setItem("ref_id", responseJSON.data.ref_id)
    // localStorage.setItem(
    //   "lpjk",
    //   JSON.stringify([
    //     {
    //       third_party_id: "Integrasi LPJK",
    //       name: "LPJK",
    //       description: "Integrasi LPJK",
    //       domain: "https://dev.aplikasisertifikasi.com/login",
    //       auth_type:
    //         "cc2c2830773567846460f1f106a2db9ef7cd64324f099805cc8ccc1397baa800e608a62bc1c8e3af",
    //       auth: [
    //         {
    //           key: "cc2c2830773567846460f1f106a2db9ef7cd64324f099805cc8ccc1397baa800e608a62bc1c8e3af",
    //           value: "list",
    //         },
    //       ],
    //     },
    //   ])
    // );
    // localStorage.setItem("integration", "lpjk");
    localStorage.setItem(
      "user_permissions",
      JSON.stringify(responseJSON.data.user_permissions)
    );
  };

  cekHttps = (url) => {
    let newUrl = url;
    let arrHttp = newUrl.toString().split(":");
    if(arrHttp[0]=='http'){
      arrHttp = ['https'].concat(arrHttp[1]);
      newUrl = arrHttp.join(":");
    }
    return newUrl;
  }

  setLocalStorageSelected = (responseJSON) => {
    // console.log("cek select lsp", responseJSON)
    localStorage.setItem("bahasa", "id");
    localStorage.setItem("secret_key", responseJSON.secret_key);
    localStorage.setItem("role", responseJSON.data.role_code);
    // localStorage.setItem("integration", "lpjk");
    localStorage.setItem(
      "selected_permission",
      JSON.stringify(responseJSON.data.selected_permission)
    );
    localStorage.setItem(
      "ref_id",
      JSON.stringify(responseJSON.data.selected_permission[0].ref_id)
    );
    let selectedPermission = responseJSON.data.selected_permission;
    let isIntegration = false;
    let isLpjkIntegration = false;
    let newListIntegration = [];
    if(selectedPermission && selectedPermission.length > 0){
      let firstSelectedPermission = selectedPermission[0];
      let integrationList = firstSelectedPermission.integration_detail;
      if(integrationList && integrationList.length > 0){
        isIntegration = true;
        integrationList.map((val, index) => {
          if(val.system_id=="4f15e5e2-b0ab-41b3-bf18-6d24bbf8d062"){
            isLpjkIntegration = true
          }
          let urlDomain = val.url_domain ? val.url_domain : '';
          let dataIntegration = {
            auth_type:val.auth_type ? val.auth_type : '',
            integration_id: val.integration_id ? val.integration_id : '',
            system_id: val.system_id ? val.system_id : '',
            system_name: val.system_name ? val.system_name : '',
            url: urlDomain,
          }
          let authParameter = val.auth_parameter ? val.auth_parameter : [];
          dataIntegration['auth_parameter'] = authParameter;
          newListIntegration.push(dataIntegration);
        })
      }
    }
    localStorage.setItem("isIntegration", isIntegration);
    localStorage.setItem("isLPJKIntegration", isLpjkIntegration);
    localStorage.setItem('integrationList', JSON.stringify(newListIntegration));
    // localStorage.setItem(
    //   "lpjk",
    //   JSON.stringify([
    //     {
    //       third_party_id: "Integrasi LPJK",
    //       name: "LPJK",
    //       description: "Integrasi LPJK",
    //       domain: "https://dev.aplikasisertifikasi.com/login",
    //       auth_type:
    //         "cc2c2830773567846460f1f106a2db9ef7cd64324f099805cc8ccc1397baa800e608a62bc1c8e3af",
    //       auth: [
    //         {
    //           key: "cc2c2830773567846460f1f106a2db9ef7cd64324f099805cc8ccc1397baa800e608a62bc1c8e3af",
    //           value: "list",
    //         },
    //       ],
    //     },
    //   ])
    // );
    let arr = [];
    const json = responseJSON.data;
    for (let index = 0; index < json.permission.length; index++) {
      const element = json.permission[index].sub_module_code;
      arr.push(element);
      localStorage.setItem("permission", arr);
    }
    // console.log("cek akhir", responseJSON)
  };

  submitContinue = (permission_id) => {
    this.setState({ loading: true });

    const { history } = this.props;
    if (permission_id === "") {
      this.setState({
        hidden: false,
        message: "Terjadi Kesalahan silahkan coba lagi",
        loading: false,
      });
    } else {
      let formData = new FormData();
      formData.append("user_permission_id", permission_id);
      insertData("/users/select_lsp", formData)
        .then((result) => {
          this.setState({ loading: true });
          let responseJSON = result;
          if (responseJSON.responseStatus === "SUCCESS") {
            this.setLocalStorageSelected(responseJSON);
            history.push("/dashboard");
            window.location.reload();
          }
        })
        .catch((error) => {
          this.setState({ loading: false });
          if (error.status === 401) {
            localStorage.clear();
            window.location.replace("/login");
          } else if (error.status === 419) {
            localStorage.clear();
            window.location.replace("/login");
          } else if (error.status == 504) {
            localStorage.clear();
            window.location.replace("/login");
          }
        });
    }
  };

  login = (event) => {
    event.preventDefault();
    const { history } = this.props;
    this.setState({ loading: true });

    if (this.state.username_email === "" || this.state.password === "") {
      this.setState({
        loading: false,
        hidden: false,
        message: "Username and password cannot empty",
      });
    }
    if (this.state.username_email && this.state.password) {
      PostData("/login", {
        username_email: this.state.username_email,
        password: this.state.password,
      }).then((result) => {
        let responseJSON = result;
        if (responseJSON.responseStatus === "SUCCESS") {
          if (
            responseJSON.data.group_id == 2 &&
            responseJSON.data.user_permissions.length === 1
          ) {
            this.setLocalStorage(responseJSON);

            this.submitContinue(
              result.data.user_permissions[0].user_permission_id
            );
          } else if (
            responseJSON.data.group_id == 2 &&
            responseJSON.data.user_permissions.length > 1
          ) {
            this.setLocalStorage(responseJSON);
            history.push("/multi-role");
          } else {
            this.setState({
              hidden: false,
              loading: false,
              message: "Anda tidak bisa melakukan login,",
            });
          }

          return;
        }
        this.setState({
          response: responseJSON.error.code,
        });
        switch (this.state.response) {
          case 400:
            localStorage.clear();
            this.setState({
              hidden: false,
              loading: false,
              message: "Minimum password length is 6",
            });
            break;

          case 422:
            localStorage.clear();
            this.setState({
              hidden: false,
              loading: false,
              message: responseJSON.error.message,
            });
            break;

          case 419:
            NotificationManager.warning(
              "Masa trial anda telah habis,Harap menghubungi Admin NAS untuk info lebih lanjut",
              "Peringatan",
              5000
            );
            localStorage.clear();
            break;

          default:
            break;
        }
      });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    if (localStorage.getItem("logged_in") || this.state.logged_in) {
      return <Redirect to={"/"} />;
    }

    const { Logo } = Brand_LSP("demo");
    const { ABI } = Brand_LSP("lsp_abi");

    return (
      <LoadingOverlay active={this.state.loading} spinner text="Please Wait...">
        <div className="app flex-row align-items-center animated fadeIn backgroundColor">
          <Modal
            isOpen={this.state.modal}
            toggle={this.toggle}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggle}>
              <b>Forgot Password</b>
            </ModalHeader>
            <ModalBody>
              <Row className="center-block" style={{ width: "75%" }}>
                <Col md="20">
                  <Input
                    className="inputBox"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Please input your email account"
                    onChange={this.handleChange}
                  />
                </Col>
                <br />
              </Row>
              <Row className="center-block">
                <Col>
                  <Button className="btn-submit" onClick={this.toggleNested}>
                    Submit
                  </Button>
                </Col>
              </Row>
              <Modal
                isOpen={this.state.nestedModal}
                toggle={this.toggleNested}
                onClosed={this.state.closeAll ? this.toggle : undefined}
              >
                <ModalHeader>Confirmation Message</ModalHeader>
                <ModalBody>
                  Your password has been reset,Please Check Your email
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={this.toggleAll}>
                    OK
                  </Button>
                </ModalFooter>
              </Modal>
            </ModalBody>
          </Modal>

          <Container>
            <Row className="justify-content-center">
              <Col md="8">
                <Card
                  className="p-4"
                  style={{
                    width: "52%",
                    margin: "auto",
                    height: "auto",
                    borderRadius: 10,
                  }}
                >
                  <CardBody>
                    <Form onSubmit={this.login}>
                      <Row className="logo">
                        <Col>
                          <img className="login" src={Logo} alt="" />
                        </Col>
                      </Row>
                      <div>
                        <Row
                          style={{
                            marginBottom: "18px",
                          }}
                        >
                          <Col md="6">
                            <Label
                              style={{
                                color: "grey",
                                fontSize: "90%",
                                fontWeight: "bold",
                              }}
                              htmlFor="username_email"
                            >
                              Email/Username
                            </Label>
                          </Col>
                          <Col sm="12">
                            <Input
                              size="large"
                              name="username_email"
                              // placeholder="Email/username"
                              onChange={this.onChange}
                            />
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <Row>
                          <Col md="6">
                            <Label
                              style={{
                                color: "grey",
                                fontSize: "90%",
                                fontWeight: "bold",
                              }}
                              htmlFor="password"
                            >
                              Password
                            </Label>
                          </Col>
                          <Col sm="12">
                            <Input.Password
                              size="large"
                              name="password"
                              // placeholder="Password"
                              onChange={this.onChange}
                            />
                          </Col>
                        </Row>
                      </div>

                      <Row style={{ marginTop: "10px" }}>
                        <Col>
                          <Button className="btn-login" type="submit">
                            Login
                          </Button>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: "10px" }}>
                        <Col>
                          <Link to={"/register"}>
                            <Button className="btn-signUp" type="register">
                              Register
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Link to={"/forgot-password/"}>
                            <Button
                              style={{ fontSize: "85%", marginTop: "10px" }}
                              className="forgot float-right"
                              color="link"
                            >
                              Forgot Password?
                            </Button>
                          </Link>
                        </Col>
                      </Row>
                    </Form>
                    <Row className="center-block">
                      <Alert
                        color="danger"
                        hidden={this.state.hidden}
                        style={{
                          marginTop: "3%",
                          fontSize: "72%",
                          marginBottom: "-7%",
                        }}
                      >
                        {this.state.message}
                      </Alert>
                    </Row>
                    <Row>
                      <Col className="colNST">
                        <h5
                          style={{
                            fontWeight: "lighter",
                            fontFamily: "inherit",
                          }}
                        >
                          NAS - LSP Login
                        </h5>
                      </Col>
                    </Row>
                    <Row
                      className="footerCopyright"
                      style={{ marginTop: "70px" }}
                    >
                      <Col>
                        Copyright &copy; 2021,
                        <a href="https://www.aplikasisertifikasi.com">NAS</a>.
                        All rights reserved
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <NotificationContainer />
          </Container>
        </div>
      </LoadingOverlay>
    );
  }
}

export default withAlert(Login);
