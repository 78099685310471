import React from "react";
import { Upload, Button, notification } from "antd";
import { Icon } from "@ant-design/compatible";

function getBase64(file, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(file);
  reader.fileName = file.name;
}

const baseFileType = ".doc, .docx, .xlsx, .xls";

class UploadFile extends React.Component {
  state = {
    loading: false,
  };

  customRequest = (info) => {
    this.setState({ loading: true });
    if (info.file) {
      getBase64(info.file, (base64) => {
        const filename = info.file.name;
        const fileSize = info.file.size;
        const mime_type = filename.split('.').pop();
        const fileType = info.file.type.split('/').shift();

        let arrAvailable = baseFileType.split(', ');
        if(this.props.typeFile){
          arrAvailable = this.props.typeFile.split(', ');
        }
        // console.log('cek arrAvailable', arrAvailable);
        // console.log('cek fileType', fileType);
        let valid = arrAvailable.find((val) => val==='.'+mime_type);
        if(!valid && arrAvailable.find((val) => val==='image/*')){
          valid = fileType === 'image' ? true : false;
        }
        if(!valid){
          if(this.props.handleErrorExt){
            this.props.handleErrorExt();
            return;
          }
          notification.error({
            placement:'topRight',
            message:'Gagal!',
            description: 'Silahkan upload file yang sesuai dengan extensi yang diperlukan!',
            style: {marginTop: '50px'}
          })
          return;
        }

        let upload = { filename, base64, fileSize, mime_type, file:info.file };
        this.setState({ loading: false });
        this.props.upload(upload);
        info.onSuccess(true);
      });
    }
  };

  render() {

    const { customButton } = this.props
    const uploadButton = (
      <Button disabled={this.props.disabled ? this.props.disabled : false}>
        <div className="upload">
          <Icon type="upload" /> Upload
        </div>
      </Button>
    );

    return (
      <Upload
        accept={this.props.typeFile ? this.props.typeFile : baseFileType}
        showUploadList={false}
        customRequest={this.customRequest}
        onRemove={this.props.onRemove}
      >
        {this.props.customButton ? customButton() : uploadButton}
      </Upload>
    );
  }
}

export { UploadFile };
